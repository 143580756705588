<template>
  <div>
    <div class="flex justify-end items-center px-4 mt-5 gap-2">
      <t-button type="button" to="/resultados" class="font-bold text-sm">
        VOLVER
      </t-button>
      <t-button
        type="button"
        @click="finishGame"
        variant="warning"
        class="font-bold text-sm"
        :disabled="game.status === 3 && !editForm"
      >
        <template v-if="game.status !== 3"> FINALIZAR PARTIDO </template>
        <template v-if="game.status === 3 && !editForm">
          PARTIDO FINALIZADO
        </template>
        <template v-if="game.status === 3 && editForm">
          EDITAR RESULTADOS
        </template>
      </t-button>
      <t-button
        v-if="game.status === 3"
        type="button"
        @click="editForm = !editForm"
        variant="error"
        class="font-bold text-sm"
      >
        <template v-if="!editForm">HABILITAR EDICIÓN</template>
        <template v-else>DESHABILITAR EDICIÓN</template>
      </t-button>
    </div>
    <t-alert
      class="my-3"
      variant="warning"
      show
      :dismissible="true"
      v-if="game.status !== 3"
    >
      Debes registrar todas las acciones del partido y luego finalizarlo,
      momento en que indicarás la cantidad final de goles de cada equipo
    </t-alert>
    <div class="pb-5 px-5 grid grid-cols-1 lg:grid-cols-3 gap-3">
      <div
        :class="{
          'lg:col-span-2': game.status !== 3 || editForm,
          'lg:col-span-3': game.status === 3 && !editForm
        }"
      >
        <t-card class="w-full mt-5" variant="form">
          <div class="w-full flex justify-center items-center" v-if="loading">
            <t-loader extraClass="border-primary-900 w-12 h-12" />
          </div>
          <div
            v-if="!loading"
            class="flex flex-col justify-center px-3 bg-white py-2 rounded-t-md"
          >
            <div class="w-full flex justify-center">
              <p class="text-sm">
                {{ game.location }}
              </p>
            </div>
            <div
              class="xl:px-5 grid grid-cols-2 md:grid-cols-5 gap-2 my-2 py-3"
            >
              <div class="h-12 w-12 mx-auto my-auto">
                <img
                  v-if="game.home_team && game.home_team.url_logo"
                  class="object-scale-down w-full h-full"
                  style="aspect-ratio: 1"
                  :src="game.home_team.url_logo"
                  alt="Home team logo"
                />
              </div>
              <div class="my-auto">
                <p class="text-sm font-bold">
                  {{ game.home_team.name }}
                </p>
                <p class="text-xs">
                  {{ game.category.name }}
                </p>
              </div>
              <div
                class="flex flex-col justify-center my-4 md:my-0 md:justify-between col-span-2 md:col-span-1"
              >
                <p class="text-xs mx-auto text-center">
                  {{ game.tournament.name }}
                </p>
                <div class="my-auto mx-auto">
                  <p class="text-2xl font-bold text-center">
                    {{ game.home_goals }} - {{ game.visit_goals }}
                  </p>
                  <p
                    class="text-md font-bold text-center"
                    v-if="game.home_penalty_goals || game.visit_penalty_goals"
                  >
                    ({{ game.home_penalty_goals }} -
                    {{ game.visit_penalty_goals }})
                  </p>
                  <p class="text-xs text-center">
                    {{ game.hour }}
                  </p>
                  <p class="text-xs text-center">
                    {{
                      game.date
                        ? new Intl.DateTimeFormat("es-ES", {
                            dateStyle: "full",
                            timeZone: "utc"
                          }).format(new Date(game.date))
                        : ""
                    }}
                  </p>
                </div>
              </div>
              <div class="h-12 w-12 mx-auto my-auto">
                <img
                  v-if="game.visit_team && game.visit_team.url_logo"
                  class="object-scale-down w-full h-full"
                  style="aspect-ratio: 1"
                  :src="game.visit_team.url_logo"
                  alt="Visit team logo"
                />
              </div>
              <div class="my-auto">
                <p class="text-sm font-bold">
                  {{ game.visit_team.name }}
                </p>
                <p class="text-xs">
                  {{ game.category.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <basic-table
              ref="results-table"
              :headers="headers"
              :url="'resultados'"
              :data="gameActions"
              :route="'/game_action'"
              :parameters="parameters"
              :customDispatch="'game/setGameActions'"
              :canEdit="false"
              :canChangeStatus="false"
            />
          </div>
        </t-card>
      </div>

      <t-card
        v-if="game.status !== 3 || editForm"
        class="w-full mt-5 col-span-1 px-3"
        variant="form"
      >
        <div class="flex justify-between px-3 bg-white py-4 rounded-t-md mt-3">
          <p class="uppercase font-bold text-sm my-auto text-center">
            RESULTADO
          </p>
        </div>

        <!--<t-input-group
          :feedback="
            showValidation && errors.first('id_scorer')
              ? errors.first('id_scorer')
              : ''
          "
          :variant="showValidation && errors.first('id_scorer') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="'Anotador'"
        >
          <t-rich-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.id_scorer"
            name="id_scorer"
            id="id_scorer"
            placeholder="Seleccione una opción"
            :disabled="loading"
            noResultsText="No se encontraron resultados"
            searchBoxPlaceholder="Busca..."
            :options="users"
            textAttribute="name"
            valueAttribute="id"
            :variant="
              showValidation && errors.first('id_scorer') ? 'danger' : ''
            "
          ></t-rich-select>
        </t-input-group>-->

        <t-input-group
          :feedback="
            showValidation && errors.first('id_action')
              ? errors.first('id_action')
              : ''
          "
          :variant="showValidation && errors.first('id_action') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="'Acción'"
        >
          <t-rich-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.id_action"
            name="id_action"
            id="id_action"
            placeholder="Seleccione una opción"
            :disabled="loading"
            noResultsText="No se encontraron resultados"
            searchBoxPlaceholder="Busca..."
            :options="actions"
            textAttribute="name"
            valueAttribute="id"
            :variant="
              showValidation && errors.first('id_action') ? 'danger' : ''
            "
          ></t-rich-select>
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('id_team')
              ? errors.first('id_team')
              : ''
          "
          :variant="showValidation && errors.first('id_team') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="form.id_action == 5 ? 'A favor de' : 'Equipo'"
        >
          <t-rich-select
            v-validate="'required'"
            data-vv-validate-on="input"
            @change="getPlayers"
            v-model="form.id_team"
            name="id_team"
            id="id_team"
            placeholder="Seleccione una opción"
            :disabled="loading"
            noResultsText="No se encontraron resultados"
            searchBoxPlaceholder="Busca..."
            :options="academies"
            textAttribute="name"
            valueAttribute="id"
            :variant="showValidation && errors.first('id_team') ? 'danger' : ''"
          ></t-rich-select>
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('id_player')
              ? errors.first('id_player')
              : ''
          "
          :variant="showValidation && errors.first('id_player') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="form.id_action == '0' ? 'Arquero' : 'Protagonista'"
        >
          <t-rich-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.id_player"
            name="id_player"
            id="id_player"
            placeholder="Seleccione una opción"
            :disabled="loading"
            noResultsText="No hay plantilla disponible para este equipo"
            searchBoxPlaceholder="Busca..."
            :options="form.id_action == 5 ? players2 : players1"
            textAttribute="name"
            valueAttribute="id"
            :variant="
              showValidation && errors.first('id_player') ? 'danger' : ''
            "
          ></t-rich-select>
        </t-input-group>

        <t-input-group
          v-if="
            form.id_action == 1 || form.id_action == 4 || form.id_action == 5
          "
          :feedback="
            showValidation && errors.first('id_goalkeeper')
              ? errors.first('id_goalkeeper')
              : ''
          "
          :variant="
            showValidation && errors.first('id_goalkeeper') ? 'danger' : ''
          "
          class="w-full md:mr-2 my-4"
          :label="'Arquero'"
        >
          <t-rich-select
            v-model="form.id_goalkeeper"
            name="id_goalkeeper"
            id="id_goalkeeper"
            v-validate="'required'"
            data-vv-validate-on="input"
            :placeholder="
              form.id_action == 1 || form.id_action == 4 || form.id_action == 5
                ? 'Seleccione una opción'
                : 'No disponible con esta acción'
            "
            :disabled="loading"
            noResultsText="No hay plantilla disponible para este equipo"
            searchBoxPlaceholder="Busca..."
            :options="form.id_action == 5 ? players1 : players2"
            textAttribute="name"
            valueAttribute="id"
            :variant="
              showValidation && errors.first('id_goalkeeper') ? 'danger' : ''
            "
          ></t-rich-select>
        </t-input-group>

        <t-input-group
          v-if="form.id_action != '0'"
          :feedback="
            showValidation && errors.first('minute')
              ? errors.first('minute')
              : ''
          "
          :variant="showValidation && errors.first('minute') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="'Minuto'"
        >
          <t-input
            v-validate="'required|amount|min:1|max:120'"
            data-vv-validate-on="input"
            v-model="form.minute"
            name="minute"
            placeholder="Ingrese el minuto"
            autocomplete="off"
            :disabled="loading"
            type="text"
            :variant="showValidation && errors.first('minute') ? 'danger' : ''"
          />
        </t-input-group>

        <t-input-group
          v-if="form.id_action != '0'"
          :feedback="
            showValidation && errors.first('aggregate')
              ? errors.first('aggregate')
              : ''
          "
          :variant="showValidation && errors.first('aggregate') ? 'danger' : ''"
          class="w-full md:mr-2 my-4"
          :label="'Minuto adicional (opcional)'"
        >
          <t-input
            v-validate="'amount|min:1'"
            data-vv-validate-on="input"
            v-model="form.aggregate"
            name="aggregate"
            placeholder="Ingrese el minuto adicional"
            autocomplete="off"
            :disabled="loading"
            type="text"
            :variant="
              showValidation && errors.first('aggregate') ? 'danger' : ''
            "
          />
        </t-input-group>

        <t-input-group
          v-if="form.id_action != '0'"
          :feedback="
            showValidation && errors.first('description')
              ? errors.first('description')
              : ''
          "
          :variant="
            showValidation && errors.first('description') ? 'danger' : ''
          "
          class="w-full md:mr-2 my-4"
          :label="'Descripción (opcional)'"
        >
          <t-textarea
            v-validate="'max:100'"
            data-vv-validate-on="input"
            v-model="form.description"
            name="description"
            placeholder="Ingrese una breve descripción"
            autocomplete="off"
            :disabled="loading"
            type="text"
            :variant="
              showValidation && errors.first('description') ? 'danger' : ''
            "
            rows="2"
          />
        </t-input-group>

        <div class="flex flex-col px-4">
          <t-button
            type="button"
            @click="addResult"
            variant="warning"
            :disabled="game.status === 3 && !editForm"
            class="font-bold text-sm"
          >
            AGREGAR RESULTADO
            <t-loader v-if="submitLoading" extraClass="ml-2" />
          </t-button>
        </div>
      </t-card>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    loading: {
      type: Boolean
    },
    submitLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: ["Acción", "Protagonista", "Arquero", "Minuto", "Equipo", ""],
      form: {
        id_team: null,
        id_scorer: null,
        id_player: null,
        id_goalkeeper: null,
        id_action: null,
        minute: null,
        aggregate: null,
        description: null
      },
      game: {
        home_team: {},
        visit_team: {},
        category: {},
        tournament: {}
      },
      showValidation: false,
      parameters: {
        id_game: this.$route.params.id,
        order_key: "minute",
        order_value: "asc"
      },
      players1Data: [],
      players2Data: [],
      editForm: false
    }
  },
  computed: {
    users() {
      return this.$store.getters["global/getItems"](
        "user",
        "unpaginated_elements"
      ).map((el) => ({
        id: el.id,
        name: `${el.first_name} ${el.last_name}`
      }))
    },
    gameActions() {
      return this.$store.getters["global/getItems"]("game", "actions").map(
        (el) => ({
          id: el.id,
          action: el.action ? el.action.name : "Portería imbatida",
          player1: el.player
            ? `${el.player.jersey_number}. ${el.player.first_name} ${el.player.last_name}`
            : "",
          player2: el.goalkeeper
            ? `${el.goalkeeper.jersey_number}. ${el.goalkeeper.first_name} ${el.goalkeeper.last_name}`
            : "-",
          minute: el.aggregate
            ? `${el.minute}+${el.aggregate}'`
            : el.minute + "'",
          academy: el.team ? el.team.name : ""
        })
      )
    },
    players1() {
      return this.players1Data.map((el) => ({
        id: el.id,
        name: `${el.jersey_number}. ${el.first_name} ${el.last_name}`
      }))
    },
    players2() {
      return this.players2Data.map((el) => ({
        id: el.id,
        name: `${el.jersey_number}. ${el.first_name} ${el.last_name}`
      }))
    },
    actions() {
      return [
        {
          id: "0",
          name: "Portería imbatida"
        }
      ].concat(
        this.$store.getters["global/getItems"]("action", "unpaginated_elements")
      )
    },
    academies() {
      return this.game.home_team
        ? [this.game.home_team, this.game.visit_team]
        : []
    }
  },
  methods: {
    finishGame() {
      this.$emit("finishGame")
    },
    forceTableQuery() {
      this.$refs["results-table"].getItems()
    },
    getItems(route, module, master, orderBy = "name") {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: orderBy,
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getPlayers() {
      this.form.id_player = null
      this.form.id_goalkeeper = null
      this.$store
        .dispatch("global/getItems", {
          route: "/player/all",
          noPaginate: true,
          params: {
            id_academy: this.form.id_team,
            id_category: this.game.id_category,
            id_subcategory: this.game.id_subcategory,
            active: 1,
            order_key: "jersey_number",
            order_value: "asc",
            verified: 1
          }
        })
        .then((response) => {
          this.players1Data = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })

      let opposite_team = this.academies.find((x) => x.id != this.form.id_team)

      if (opposite_team) {
        this.$store
          .dispatch("global/getItems", {
            route: "/player/all",
            noPaginate: true,
            params: {
              id_academy: opposite_team.id,
              id_category: this.game.id_category,
              id_subcategory: this.game.id_subcategory,
              active: 1,
              order_key: "jersey_number",
              order_value: "asc",
              verified: 1
            }
          })
          .then((response) => {
            this.players2Data = response.data
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      }
    },

    addResult() {
      if (this.form.id_action == "0") {
        this.form.minute = 0
        this.form.aggregate = null
        this.form.description = null
      }

      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("addResult")
        }
      })
    }
  },
  created() {
    this.getItems("/action/all", "action", true, "id")
    //this.getItems("/user/all", "user", false, "first_name")
  }
}
</script>
