<template>
  <div class="px-5">
    <Form
      title="Editar resultado"
      ref="resultForm"
      :deletable="true"
      :loading.sync="loading"
      :submitLoading.sync="submitLoading"
      :finishLoading.sync="finishLoading"
      @addResult="addResult"
      @finishGame="finishGame"
    />
    <t-modal
      header="Finalizar partido"
      :value="finishGameModal"
      @closed="closeModal"
    >
      <div class="p-3">
        <p class="mb-1">
          Para finalizar el partido debes indicar llenar los siguientes campos
        </p>

        <div class="flex flex-col md:flex-row items-center justify-around my-2">
          <t-input-group
            :feedback="
              showValidation && errors.first('home_goals')
                ? errors.first('home_goals')
                : ''
            "
            :variant="
              showValidation && errors.first('home_goals') ? 'danger' : ''
            "
            class="w-full md:w-1/2 md:mx-2"
            :label="'Goles del equipo local'"
          >
            <t-input
              v-validate="'required|numeric'"
              data-vv-validate-on="input"
              v-model="finishForm.home_goals"
              name="home_goals"
              placeholder="Ingrese la cantidad de goles"
              autocomplete="off"
              :disabled="loading"
              type="text"
              :variant="
                showValidation && errors.first('home_goals') ? 'danger' : ''
              "
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation && errors.first('visit_goals')
                ? errors.first('visit_goals')
                : ''
            "
            :variant="
              showValidation && errors.first('visit_goals') ? 'danger' : ''
            "
            class="w-full md:w-1/2 md:mx-2"
            :label="'Goles del equipo visitante'"
          >
            <t-input
              v-validate="'required|numeric'"
              data-vv-validate-on="input"
              v-model="finishForm.visit_goals"
              name="visit_goals"
              placeholder="Ingrese la cantidad de goles"
              autocomplete="off"
              :disabled="loading"
              type="text"
              :variant="
                showValidation && errors.first('visit_goals') ? 'danger' : ''
              "
            />
          </t-input-group>
        </div>

        <div class="flex flex-col md:flex-row items-center justify-around my-2">
          <t-input-group
            :feedback="
              showValidation && errors.first('home_penalty_goals')
                ? errors.first('home_penalty_goals')
                : ''
            "
            :variant="
              showValidation && errors.first('home_penalty_goals')
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mx-2"
            :label="'Goles de penalty del equipo local'"
          >
            <t-input
              v-validate="'required|numeric'"
              data-vv-validate-on="input"
              v-model="finishForm.home_penalty_goals"
              name="home_penalty_goals"
              placeholder="Ingrese la cantidad de goles"
              autocomplete="off"
              :disabled="loading"
              type="text"
              :variant="
                showValidation && errors.first('home_penalty_goals')
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation && errors.first('visit_penalty_goals')
                ? errors.first('visit_penalty_goals')
                : ''
            "
            :variant="
              showValidation && errors.first('visit_penalty_goals')
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mx-2"
            :label="'Goles de penalty del equipo visitante'"
          >
            <t-input
              v-validate="'required|numeric'"
              data-vv-validate-on="input"
              v-model="finishForm.visit_penalty_goals"
              name="visit_penalty_goals"
              placeholder="Ingrese la cantidad de goles"
              autocomplete="off"
              :disabled="loading"
              type="text"
              :variant="
                showValidation && errors.first('visit_penalty_goals')
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            type="button"
            variant="error"
            @click="closeModal"
            class="mr-2"
          >
            No
          </t-button>
          <t-button type="button" @click="finishGame" :disabled="finishLoading"
            >Finalizar partido <t-loader v-if="finishLoading" extraClass="ml-2"
          /></t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./ResultForm.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      finishLoading: false,
      finishForm: {
        status: 3,
        home_goals: null,
        visit_goals: null,
        home_penalty_goals: null,
        visit_penalty_goals: null
      },
      showValidation: true,
      finishGameModal: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    closeModal() {
      this.finishForm = {
        status: 3,
        home_goals: null,
        visit_goals: null,
        home_penalty_goals: null,
        visit_penalty_goals: null
      }
      this.finishGameModal = false
    },
    finishGame() {
      if (!this.finishGameModal) {
        this.finishGameModal = true
        return
      }
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.finishLoading = true
          this.$store
            .dispatch("global/update", {
              payload: {
                id: this.$route.params.id,
                ...this.finishForm
              },
              route: "/game/update",
              module: "game"
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification("master-update", response.code, this.$snotify)
              })
              this.finishLoading = false

              if ([200, 201].includes(response.code)) {
                this.getItem()
                this.closeModal()
              }
            })
            .catch((error) => {
              this.finishLoading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },
    addResult() {
      this.submitLoading = true
      let form = this.$refs.resultForm.form
      this.$store
        .dispatch("global/create", {
          payload: {
            id_team: form.id_team,
            id_game: this.$route.params.id,
            id_scorer: this.user.id,
            id_player: form.id_player,
            id_goalkeeper: form.id_goalkeeper,
            id_action: form.id_action,
            minute: form.minute,
            aggregate: form.aggregate ? form.aggregate : null,
            description: form.description ? form.description : null
          },
          route: "/game_action/save"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          if ([200, 201].includes(response.code)) {
            this.$refs.resultForm.forceTableQuery()
            this.$refs.resultForm.form = {
              id_team: null,
              id_scorer: this.user.id,
              id_player: null,
              id_goalkeeper: null,
              id_action: null,
              minute: null,
              aggregate: null,
              description: null
            }
            this.$refs.resultForm.showValidation = false
          }
          this.submitLoading = false
        })
        .catch((error) => {
          this.submitLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/game",
          module: "game",
          id: this.$route.params.id
        })
        .then((response) => {
          this.$refs.resultForm.game = response.data
          this.finishForm = {
            status: 3,
            home_goals: response.data.home_goals,
            visit_goals: response.data.visit_goals,
            home_penalty_goals: response.data.home_penalty_goals,
            visit_penalty_goals: response.data.visit_penalty_goals
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
